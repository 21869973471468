//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-200:_5504,_6372,_7960,_6904,_5228,_7972,_7072,_3327;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-200')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-200', "_5504,_6372,_7960,_6904,_5228,_7972,_7072,_3327");
        }
      }catch (ex) {
        console.error(ex);
      }