
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function verifyFitmentRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3194'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3396'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown' }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', Convermax.t(this.title) || this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7541'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7544'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)), !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'key': '2558'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', '', Convermax.t(this.title) || this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, Convermax.t('...loading...')) : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    function onClick2() {
        window.Convermax.showFitmentTable();
    }
    function repeatSplittedValue3(verifyFitmentInfo, value, valueIndex, splittedValue, splittedValueIndex) {
        return [_createElement('li', { 'key': '92311' }, splittedValue)];
    }
    function repeatValue4(verifyFitmentInfo, value, valueIndex) {
        return [_map(value.split('\n'), repeatSplittedValue3.bind(this, verifyFitmentInfo, value, valueIndex))];
    }
    function repeatSplittedValue5(verifyFitmentInfo, key, keyIndex, value, valueIndex, splittedValue, splittedValueIndex) {
        return [_createElement('li', { 'key': '98551' }, splittedValue)];
    }
    function repeatValue6(verifyFitmentInfo, key, keyIndex, value, valueIndex) {
        return [_map(value.split('\n'), repeatSplittedValue5.bind(this, verifyFitmentInfo, key, keyIndex, value, valueIndex))];
    }
    function repeatKey7(verifyFitmentInfo, key, keyIndex) {
        return [_createElement('div', {
                'className': 'cm_verify-fitment_vehicle-info',
                'key': '94421'
            }, _createElement('div', { 'className': 'cm_verify-fitment_vehicle-info__key' }, key, ':'), _createElement.apply(this, [
                'ul',
                { 'className': 'cm_verify-fitment_vehicle-info__values' },
                _map(verifyFitmentInfo[key], repeatValue6.bind(this, verifyFitmentInfo, key, keyIndex))
            ]))];
    }
    function scopeVerifyFitmentInfo8() {
        var verifyFitmentInfo = this.productData?.verify_fitment_info;
        return [verifyFitmentInfo && Object.keys(verifyFitmentInfo).length > 0 ? _createElement.apply(this, [
                'div',
                {
                    'className': 'cm_verify-fitment_vehicle-info__container',
                    'key': '87881'
                },
                verifyFitmentInfo['Notes'] ? _createElement.apply(this, [
                    'ul',
                    {
                        'className': 'cm_verify-fitment_vehicle-notes',
                        'key': '9036'
                    },
                    _map(verifyFitmentInfo['Notes'], repeatValue4.bind(this, verifyFitmentInfo))
                ]) : null,
                _map(Object.keys(verifyFitmentInfo).filter(v => v !== 'Notes'), repeatKey7.bind(this, verifyFitmentInfo))
            ]) : null];
    }
    function repeatSelects9(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3194'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3396'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown' }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', Convermax.t(this.title) || this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7541'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7544'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)), !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'key': '2558'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', '', Convermax.t(this.title) || this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, Convermax.t('...loading...')) : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__verify-fitment' }, this.template === 'active' ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'key': '68'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, Convermax.t('Verify fits with your vehicle')), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle
    }, '\n    ', Convermax.t('GO'), '\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle
    }, '\n    ', Convermax.t('Clear'), '\n  ')))) : null, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__' + this.fits + ' cmTemplate_locked',
        'key': '6253'
    }, _createElement('div', { 'className': 'cm_verify-fitment_fitment__container' }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-check',
            'height': '20px',
            'role': 'image',
            'viewBox': '0 0 492 492',
            'key': '65050'
        }, _createElement('path', { 'd': 'm484.13 104.48-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856l-226.45 226.45-141.48-141.49c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112c-5.068 5.076-7.852 11.836-7.852 19.036 0 7.204 2.784 13.96 7.852 19.028l159.74 159.74c0.212 0.3 0.436 0.58 0.696 0.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h0.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c0.26-0.256 0.48-0.468 0.612-0.684l244.78-244.76c10.5-10.476 10.5-27.52 4e-3 -38.008z' }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-times',
            'height': '20px',
            'role': 'image',
            'viewBox': '0 0 22 22',
            'key': '72350'
        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '74270'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_body' }, this.fits === 'yes' ? [
        Convermax.t('This product fit your vehicle'),
        ' ',
        this.vehicleString || Convermax.t('Vehicle')
    ] : null, this.fits === 'no' ? [
        Convermax.t('This Product DOES NOT Fit Your'),
        ' ',
        this.vehicleString || Convermax.t('Vehicle')
    ] : null, _createElement('a', {
        'className': 'cm_verify-fitment_change',
        'onClick': this.changeVehicle
    }, Convermax.t('Change'))), this.fits === 'no' ? _createElement('button', {
        'className': 'cm_verify-fitment_details',
        'onClick': onClick2.bind(this),
        'key': '8540'
    }, '\n          FITMENT DETAILS\n        ') : null, this.fits === 'yes' ? [scopeVerifyFitmentInfo8.apply(this, [])] : null))) : null, this.template === 'inexact' ? _createElement('div', {
        'className': 'cm_vehicle-widget_specifier cmTemplate_inexact',
        'key': '10219'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects9.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button button button--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle
    }, '\n    ', Convermax.t('GO'), '\n  ')))) : null, this.template === 'universal' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__universal cmTemplate_universal',
        'key': '16102'
    }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-check',
            'height': '20px',
            'role': 'image',
            'viewBox': '0 0 492 492',
            'key': '163010'
        }, _createElement('path', { 'd': 'm484.13 104.48-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856l-226.45 226.45-141.48-141.49c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112c-5.068 5.076-7.852 11.836-7.852 19.036 0 7.204 2.784 13.96 7.852 19.028l159.74 159.74c0.212 0.3 0.436 0.58 0.696 0.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h0.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c0.26-0.256 0.48-0.468 0.612-0.684l244.78-244.76c10.5-10.476 10.5-27.52 4e-3 -38.008z' }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-times',
            'height': '20px',
            'role': 'image',
            'viewBox': '0 0 22 22',
            'key': '170310'
        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '172230'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, Convermax.t('Universal fit')), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, Convermax.t('This product may require modification.')))) : null, this.template === 'unknown' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__unknown cmTemplate_unknown',
        'key': '18004'
    }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-check',
            'height': '20px',
            'role': 'image',
            'viewBox': '0 0 492 492',
            'key': '181970'
        }, _createElement('path', { 'd': 'm484.13 104.48-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856l-226.45 226.45-141.48-141.49c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112c-5.068 5.076-7.852 11.836-7.852 19.036 0 7.204 2.784 13.96 7.852 19.028l159.74 159.74c0.212 0.3 0.436 0.58 0.696 0.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h0.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c0.26-0.256 0.48-0.468 0.612-0.684l244.78-244.76c10.5-10.476 10.5-27.52 4e-3 -38.008z' }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-times',
            'height': '20px',
            'role': 'image',
            'viewBox': '0 0 22 22',
            'key': '189270'
        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '191190'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, Convermax.t('No Fitment Data')), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, Convermax.t('This product doesn"t have fitment application data. Please verify fitment manually.')))) : null);
}
        export const componentNames = []