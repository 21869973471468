
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function singleVehicleGarageRT () {
    return _createElement('div', { 'className': 'garage-container' }, [this.changeButton(function () {
            return _createElement('div', { 'className': 'cm_changeButton' }, _createElement('i', { 'className': 'material-icons-outlined' }, 'garage'), this.template === 'active' ? _createElement('div', {
                'className': 'active-container cmTemplate_active',
                'key': '84'
            }, Convermax.t('Select your vehicle')) : null, this.template === 'locked' ? _createElement('div', {
                'className': 'locked-container cmTemplate_locked',
                'key': '234'
            }, _createElement('span', { 'className': 'model' }, this.vehicleString), _createElement('span', { 'className': 'change' }, Convermax.t('Change'))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], this.template === 'locked' ? _createElement('div', {
        'className': 'clear-garage cmTemplate_locked',
        'key': '1012'
    }, _createElement('div', {
        'className': 'cm_button',
        'data-cm-role': 'discard-vehicle'
    }, _createElement('span', {}, 'Clear'))) : null);
}
        export const componentNames = ["cm:changeButton"]