import t from './localization.js';
import { getVehicleString, updateVehicleVisualization } from './vehicle-visualizer.js';
import { bigcommerceAppClientId } from '../_common/constants.js';

window.Convermax.t = t;

const baseFitmentFields = ['Year', 'Make', 'Model', 'Engine'];
const extraFitmentFields = [
  'QualifierText',
  'BedLength',
  'BodyDoor',
  'BodyType',
  'Drive',
  'BrakeSystem',
  'BrakeAbs',
  'EngineAspiration',
  'EngineDesignation',
  'EngineVersion',
  'EngineVin',
  'FrontBrakeType',
  'RearBrakeType',
  'FrontSpringType',
  'RearSpringType',
  'SteeringSystem',
  'SteeringType',
  'MfrBodyCode',
  'Transmission',
];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal', 'Vehicle', 'Fitment'];

const categorySelectionPageUrl = '/produktkategorien/';
const brandSelectionPageUrl = '/brands/';
const genericVehiclePageUrl = '/vehicle/';

window.Convermax.showFitmentTable = () =>
  window.document.querySelector('a[href="#tab-fitment-details"]').click();

const getDefaultCountryFromAPI = async (context) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${context.jamersanWebhookApiToken}`);

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  try {
    const response = await fetch(
      `https://webhooks.${window.location.host}/api/customer/${context.customer.id}/default-country`,
      requestOptions,
    );

    if (response.ok) {
      return response.text();
    }
  } catch (e) {}
  return context.jamersanCustomerDefaultCountry ?? 'DE';
};

const customerDefaultCountry = async (context) => {
  let customerCountry = context.jamersanCustomerDefaultCountry;
  const isCustomerPage = window.location.href.indexOf('account.php') !== -1;

  if (!context.customer) {
    localStorage.removeItem('customer_default_country');
    return null;
  }

  customerCountry = localStorage.getItem('customer_default_country') || null;

  if (!customerCountry || isCustomerPage) {
    customerCountry = await getDefaultCountryFromAPI(context);
    localStorage.setItem('customer_default_country', customerCountry);
  }

  return customerCountry;
};

async function getCountry() {
  function ensureContextIsSet(timeout) {
    const start = Date.now();
    return new Promise(waitForContext);

    function waitForContext(resolve, reject) {
      if (window.jsContext) {
        resolve(window.jsContext);
      } else if (timeout && Date.now() - start >= timeout) {
        reject(() => {
          console.error(
            'timeout: jsContext is not available, therefore customerDefaultCountry will not be taken into account',
          );
          return '';
        });
      } else {
        setTimeout(waitForContext.bind(this, resolve, reject), 30);
      }
    }
  }

  return await ensureContextIsSet(10000).then(
    async function () {
      return (await customerDefaultCountry(window.jsContext)) || '';
    },
    () => '',
  );
}

const getLang = () => localStorage.getItem('cm_lang') || 'de';

Convermax.getLang = getLang;

let searchResultsVehicleVisualizer = null;

function updateCallback() {
  window.document.body.classList.add(`cm_${getLang()}`);

  const pagination = window.document.querySelector('.cm_SearchHeader .cm_pagination');

  // handle case, when SearchHeader doesn't contains pagination
  if (!pagination) {
    window.document.querySelector('.cm_SearchHeader .cm_sort')?.classList.add('without-pagination');
  } else {
    window.document.querySelector('.cm_SearchHeader .cm_sort')?.classList.remove('without-pagination');
  }

  const vehicleString = getVehicleString();
  if (!vehicleString) {
    return;
  }

  searchResultsVehicleVisualizer = updateVehicleVisualization({
    autoSyncVisualizer: searchResultsVehicleVisualizer,
    autoSyncConfig: {
      elId: 'cm_vehicle-visualization',
      height: '150px',
      vehicleImageSize: 640,
      vehicleAngles: [32],
      showColorPicker: false,
    },
    vehicleString,
  });
}

const onVehicleDiscarded = () => {
  [...window.document.body.classList]
    .filter((cl) => cl.startsWith('cm_') && (cl.endsWith('-visualized') || cl.endsWith('-failed')))
    .forEach((className) => {
      window.document.body.classList.remove(className);
    });
};

function AfterInit() {
  // handle language switch for localization
  // this is a very serious crutch
  // ajax is much better than an extra reboot, in fact we broke good behavior,
  // our code cannot selectively relerate when changing Window.
  // pieces of code inside the templates depending on the change of Window are not redrawn when it is changed.
  // therefore, we clearly update the page when switching the tongue

  const intervalId = setInterval(() => {
    const weglotSwitcher = window.document.querySelector('.weglot-container aside li');
    if (weglotSwitcher) {
      window.document.querySelector('.weglot-container aside li').addEventListener('click', (e) => {
        const selectedLang = e.currentTarget.dataset.l;
        localStorage.setItem('cm_lang', selectedLang);
        setTimeout(() => window.location.reload(), 1000);
      });
      clearInterval(intervalId);
    }
  }, 500);
}

function getLocalPreselection(pageType, defaults) {
  if (pageType === 'category' || pageType === 'brand') {
    // defaults.termFromBreadcrumbs = "Homepage   navigate_next>Abgasanlage   navigate_next";
    const breadcrumbs = defaults.termFromBreadcrumbs
      .replaceAll('navigate_next', '')
      .split('>')
      .map((i) => i.trim())
      .join('>')
      .replace('Homepage>', '')
      .replace('Startseite>', '')
      .replace('Produktkategorien>', '');

    if (breadcrumbs === 'Shop All' || breadcrumbs === 'Shop Alle') {
      return;
    }

    return [
      {
        field: pageType === 'category' ? 'category' : 'brand_name',
        term: breadcrumbs,
        treeLevel: pageType === 'category' ? breadcrumbs.split('>').length - 1 : null,
      },
    ];
  }

  return defaults.getter(pageType);
}

globalThis.Convermax.handleProductCompareClick = () => {
  const productsIdsToCompare = [...window.document.querySelectorAll('input[name="products[]"]:checked')].map(
    (product) => product.dataset.compareId,
  );

  const compareButton = window.document.body.querySelector('.cm_card-compare');

  if (!compareButton) {
    return;
  }

  const compareLink = window.document.body.querySelector('.cm_card-compare_link');
  const compareCount = window.document.body.querySelector('.cm_card-compare_count');

  compareCount.textContent = productsIdsToCompare.length;
  compareButton.classList.toggle('show', productsIdsToCompare.length > 1);
  compareLink.href = `/compare/${productsIdsToCompare.join('/')}/`;
};

globalThis.Convermax.isLoggedIn = window.document.querySelector(
  '.header-navigation__account #account-dropdown',
);

const customerGroupId = getGroupId();
async function getGroupId() {
  if (!window.Convermax?.isLoggedIn) {
    return '';
  }
  const groupId = await new Promise((resolve) => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (xmlHttp.readyState === 4) {
        if (xmlHttp.status === 200) {
          const jwt = xmlHttp.responseText;
          const [, jsonPart] = jwt.split('.');
          const { customer } = JSON.parse(atob(jsonPart));
          resolve(customer.group_id);
        } else if (xmlHttp.status === 404) {
          resolve(null);
        } else {
          resolve(null);
        }
      }
    };
    xmlHttp.open('GET', `/customer/current.jwt?app_client_id=${bigcommerceAppClientId}`, true);
    xmlHttp.send();
  });
  return groupId || '';
}

export default {
  platform: 'bigcommerce',
  AfterInit,
  pagination: {
    edgeRange: 2,
    centralRange: 1,
  },
  SearchRequestDefaults: {
    pageSize: 24,
    extra: {
      country: getCountry(),
      lang: getLang(),
      customerGroupId,
    },
  },
  autocomplete: {
    requestDefaults: {
      extra: {
        country: getCountry(),
        lang: getLang(),
        customerGroupId,
      },
    },
  },
  getLocalPreselection,
  product: {
    priceFormatter: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: genericVehiclePageUrl, field: 'category' },
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    getFitmentSearchTitle: () => t('Parts'),
    categorySelectionPageRedirect: true,
    expectResponseRedirect: true,
    isAutoRedirectDisabled: true,
    onVehicleDiscarded,
  },
  page: {
    getPageType: (defaults) =>
      window.document.querySelector('body.page_type__default')
        ? 'home'
        : window.document.querySelector('body.page_title__search')
          ? 'search'
          : window.location.pathname === genericVehiclePageUrl
            ? 'generic-vehicle-page'
            : window.document.querySelector('body.page_type__brands') ||
                window.location.pathname === categorySelectionPageUrl
              ? 'parts'
              : window.document.querySelector('body.page_type__category')
                ? 'category'
                : defaults.getter(),
  },
  facets: {
    simpleFacet: { fields: ['review'], name: 'reviewFacet' },
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.page_type__category .page',
      template: 'SearchPage',
      visibleIf: () =>
        !window.document.querySelector('.page.vehicle-page') &&
        window.location.pathname !== categorySelectionPageUrl,
    },
    {
      name: 'BrandPage',
      type: 'SearchPage',
      location: 'body.page_type__brand .page-content',
      template: 'SearchPage',
    },
    {
      name: 'GenericVehicleSearchPage',
      type: 'SearchPage',
      location: {
        lastChildOf: '.page.vehicle-page .recommended-products',
        class: 'cm_generic-vehicle-page',
      },
      template: 'MainContent',
    },
    {
      name: 'GenericVehicleCategories',
      type: 'FacetTiles',
      location: '.page.vehicle-page div[data-content-region="vehicle_page_top_categories"]',
      template: 'facetTiles/subtitles',
      facetField: 'category',
      isMultiLevel: true,
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select',
      sortEntries: {
        'relevance': t('Best Match'),
        ...(getLang() === 'en' && { 'name_en': t('Name: A to Z'), 'name_en:desc': t('Name: Z to A') }),
        ...(getLang() === 'de' && { 'name': t('Name: A to Z'), 'name:desc': t('Name: Z to A') }),
        'price': t('Price: Low to High'),
        'price:desc': t('Price: High to Low'),
      },
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'CategoryFacet',
      type: 'FacetPanel',
      fields: 'category',
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      initCollapsed: true,
      ignoreFields,
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields: [...ignoreFields, 'category'],
    },
    {
      name: 'VehicleSpecs',
      type: 'VehicleWidget',
      fields: ['BodyType', 'Transmission', 'FrontBrakeType'],
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'SearchBox',
      location: '.header-navigation__search',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxMobile',
      type: 'SearchBoxDialogButton',
      location: '.navPages-quickSearch .container',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '.navPages-list .navPages-item:nth-child(2)',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      isAlwaysColumnLayout: true,
      fields: baseFitmentFields,
      redirectUrl: '/vehicle/',
    },
    {
      name: 'HomeVehicleWidget__desktop',
      type: 'VehicleWidget',
      location: '.car-selection',
      template: 'fitmentSearch/homeVehicleWidget',
      fields: baseFitmentFields,
      columnBreakpoint: 400,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      fields: baseFitmentFields,
      columnBreakpoint: 700,
    },
    {
      name: 'Garage',
      location: '.header-navigation__garage',
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SingleVehicleGarage',
      location: {
        insertBefore: '.page.vehicle-page .top-categories',
        class: 'cm_garage__single-vehicle vehicle-title__body',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'VerifyFitment__desktop',
      type: 'VehicleWidget',
      location: { firstChildOf: '.desktop-only #tab-description' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'VerifyFitment__mobile',
      type: 'VehicleWidget',
      location: { firstChildOf: '.mobile-only #tab-description' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableDesktop',
      type: 'FitmentTable',
      location: '.desktop-only #tab-fitment-details',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FitmentTableMobile',
      type: 'FitmentTable',
      location: '.mobile-only #tab-fitment-details',
      template: 'fitmentSearch/fitmentTable',
      columnBreakpoint: 700,
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: 'body.page_type__category .page',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: { replace: 'body.page_type__brands .brandGrid' },
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      fields: baseFitmentFields,
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
    {
      name: 'VehicleWheelDialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/vehicleWheelDialog',
    },
    {
      name: 'VehicleWheelDialogButton',
      type: 'DialogButton',
      location: {
        lastChildOf: 'body',
        class: 'cm_hide',
      },
      template: 'fitmentSearch/customDialogButton',
      dialogName: 'VehicleWheelDialog',
    },
  ],
};
