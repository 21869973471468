/* eslint-disable camelcase */

const englishStrings = [
  '...loading...',
  'Add a vehicle',
  'Add to Cart',
  'Compare',
  'and less',
  'and more',
  'and try to search for something else',
  'and up',
  'APPLY FILTER & CLOSE',
  'Best Match',
  'Browse all products',
  'Change',
  'CLEAR FILTER',
  'Clear garage',
  'clear your search',
  'Clear',
  'Current Search:',
  'did not match any of the products we carry. We searched for',
  'Displaying universal products only. No vehicle specific products found for',
  "doesn't have any",
  'Easily find the parts you need',
  'Engine',
  'Enter',
  'Filters',
  'Filters:',
  'Get products for your vehicle',
  'GO',
  'here',
  'instead',
  'Make',
  'Max Price',
  'Min Price',
  'Model',
  'My',
  'Name: A to Z',
  'Name: Z to A',
  'Network error. We could not reach the search server.',
  'No entries found',
  'No Fitment Data',
  'No results were found for your search',
  'of',
  'Part No.:',
  'Parts shown below are based on keyword search only',
  'parts that fit',
  'Parts',
  'Press',
  'Price: High to Low',
  'Price: Low to High',
  'Results',
  'Search',
  'What are you looking for?',
  'Select your vehicle',
  'selection',
  'Show Less...',
  'Show More...',
  'Start Over',
  'Store your vehicle in the garage',
  'The request took too long. Please',
  'These parts will not fit your',
  `This category doesn't have any`,
  'This Product DOES NOT Fit Your',
  'This product doesn"t have fitment application data. Please verify fitment manually.',
  'This product fit your vehicle',
  'This product may require modification.',
  'This',
  'to clear',
  'to view parts that fit this vehicle.',
  'to',
  'try your search again',
  'Universal fit',
  'Universal',
  'vehicle selection and browse all parts',
  'Vehicle',
  'Verify fits with your vehicle',
  'We failed to process your request',
  'We performed a partial search instead.',
  'We were unable to find products that match entire query',
  'Year',
  'You may',
  'Your search for',
  'Next',
  'Previous',
  'Sold out',
  'Vehicle specs',
  'Reset',
  'Create new wish list',
  'Add to my wish list',
];

// Used when WeGlot's auto-translation isn't suitable
const fixedTranslations = {
  ['In Stock']: 'Lagernd',
  ['Tüv']: 'Tüv',
  ['What are you looking for?']: 'Wonach suchst Du?',
  ['Select your vehicle']: 'Wähle Dein Fahrzeug',
  ...(window.Convermax?.config?.fixedTranslations || {}),
};

function htmlDecode(input) {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
}

function fetchTranslation(strings) {
  if (!window.XMLHttpRequest) {
    return;
  }

  const translateRequest = {
    l_from: 'en',
    l_to: 'de',
    words: strings.map((s) => ({ t: 1, w: s })),
    request_url: window.location.href,
  };

  let response;
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = function () {
    if (xmlHttp.readyState === 4) {
      if (xmlHttp.status === 200) {
        const json = xmlHttp.responseText;
        response = JSON.parse(json);
      } else if (xmlHttp.status === 404) {
        response = null;
      } else {
        response = null;
      }
    }
  };
  xmlHttp.open(
    'POST',
    'https://cdn-api-weglot.com/translate?api_key=wg_439143df05d156d64b2c3e86b80546203',
    false,
  );
  xmlHttp.send(JSON.stringify(translateRequest));

  if (!response) {
    return new Map();
  }

  const dictionary = new Map();
  response.from_words.forEach((w, i) => {
    dictionary.set(w, htmlDecode(response.to_words[i]));
  });

  return dictionary;
}

function getEnToDeDictionary(stings) {
  if (typeof window === 'undefined' || !window.sessionStorage) {
    return;
  }

  const cache = sessionStorage.wg_translations_en_to_de
    ? JSON.parse(sessionStorage.wg_translations_en_to_de)
    : {};

  let enToDeDictionary;
  if (Object.keys(cache).length) {
    enToDeDictionary = new Map();
    for (const [en, de] of Object.entries(cache)) {
      enToDeDictionary.set(en, de);
    }
    return enToDeDictionary;
  }

  try {
    enToDeDictionary = fetchTranslation(stings);
  } catch {
    enToDeDictionary = new Map();
  }

  enToDeDictionary.forEach((v, k) => {
    cache[k] = v;
  });

  sessionStorage.wg_translations_en_to_de = JSON.stringify(cache);

  return enToDeDictionary;
}

const enToDeDictionary = getEnToDeDictionary(englishStrings);

function t(string) {
  if (Convermax.getLang() === 'en') {
    return string;
  }

  if (string in fixedTranslations) {
    return fixedTranslations[string];
  }

  if (!enToDeDictionary.has(string)) {
    return string;
  }

  return enToDeDictionary.get(string);
}

export default t;
